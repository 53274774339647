import _ from 'lodash';
import i18next from 'i18next';
import Backend from 'i18next-chained-backend';
import { initReactI18next } from 'react-i18next';
import HttpBackend, { HttpBackendOptions } from 'i18next-http-backend';
import HttpCodes from 'http-status-codes';
import { flux } from '@/core/flux.module';
import Highcharts from 'other_components/highcharts';
import { LOCALE_DATA, LocaleKey, LOCALES } from '@/utilities/i18n.constants';
import moment from 'moment-timezone';
import { LangOptions } from 'highcharts';
import { setDateFnsLocale as setStoreLocale } from '@/workbench/workbench.actions';

function setMomentLocale(browserLanguage: string) {
  const momentPath = MOMENT_LOCALE_DATA[browserLanguage];
  return momentPath?.().then((localeResponse: { default: { _config: moment.LocaleSpecification | null } }) => {
    moment.defineLocale(browserLanguage, localeResponse.default._config);
    moment.locale(browserLanguage);
  });
}

function setDateFnsLocale(browserLanguage: string) {
  const dateFnsLoader = DATE_FNS_LOCALE_DATA[browserLanguage];
  return dateFnsLoader?.().then((localeData) => {
    setStoreLocale(localeData);
  });
}

export async function init18n() {
  const browserLanguage = getBrowserLanguage().toLowerCase();
  if (browserLanguage !== 'en' && browserLanguage !== 'en-us') {
    setMomentLocale(browserLanguage);
    setDateFnsLocale(browserLanguage);
  }

  let manifest: Record<string, string> = {};
  const remoteBackend: HttpBackendOptions = {
    loadPath: async ([language]: string[]) => {
      manifest = await fetchI18nManifest(manifest);
      return manifest[language];
    },
  };
  const localBackend: HttpBackendOptions = {
    loadPath: ([language]: string[]) => _.find(LOCALE_DATA, ({ key }) => key === language)!.translationPath,
  };

  i18next
    .use(Backend)
    .use(initReactI18next)
    .init({
      lng: 'en',
      fallbackLng: 'en',
      compatibilityJSON: 'v3',
      supportedLngs: _.values(LOCALES),
      nonExplicitSupportedLngs: false,
      interpolation: {
        skipOnVariables: true,
        escapeValue: false,
      },
      backend: {
        backends: [HttpBackend, HttpBackend],
        backendOptions: [remoteBackend, localBackend],
      },
      react: {
        useSuspense: true,
      },
    });
}

/**
 * For use with the external i18n server. Upon first run it fetches manifest file (created as part of the build
 * process by Vite) and parses it into a map of language code to file URL.
 *
 * @param manifest The current map holding the language code to file URL
 * @return The populated manifest file, or undefined if an error occurs
 */
export async function fetchI18nManifest(manifest: Record<string, string>) {
  if (!_.isEmpty(manifest)) {
    return manifest;
  }

  try {
    const response = await fetch(`/manifest-i18n.json`, { method: 'GET' });
    if (response.status !== HttpCodes.OK) {
      throw new Error(`Failed to fetch i18n manifest: ${response.status}`);
    }

    const manifestData: Record<string, { file: string }> = await response.json();
    return _.transform(
      manifestData,
      (result, value, key) => {
        result[key.replace(/.*\/(.*?).json/, '$1')] = value.file;
      },
      {} as Record<string, string>,
    );
  } catch (e) {
    return manifest;
  }
}

/**
 * Return only the language portion of the locale string. For example, a locale of "pt-BR" would return "pt".
 *
 * @param locale - The locale
 */
export function getLanguage(locale: LocaleKey) {
  return locale.slice(0, 2);
}

export function getBrowserLanguage() {
  return navigator.language || (navigator as any).userLanguage;
}

/**
 * Switches to the provided language. This function also sets the moment locale and loads the appropriate
 * Highcharts translations file, and makes sure that the Tools are properly translated.
 *
 * @param locale - The locale key
 */
export async function switchLanguage(locale: LocaleKey) {
  await i18next.changeLanguage(locale);
  Highcharts.setOptions({ lang: i18next.t('HIGHCHARTS', { returnObjects: true }) as LangOptions });
  // NOTE: we do not want to set a new locale for moment as users are used to having it use their browser
  // locale (CRAB-24568)
  flux.dispatch('INVESTIGATE_TRIGGER_INVESTIGATE_TOOL_TRANSLATION');
  flux.dispatch('SET_USER_LANGUAGE', locale);
}

/**
 * Vantage uses react-date-picker which requires date-fns for date formatting.
 * This list should be kept up to date with the moment import lists.
 */
type DateFnsLocaleData = { [key in string]: () => Promise<any> };
export const DATE_FNS_LOCALE_DATA: DateFnsLocaleData = {
  'af': () => import('date-fns/locale/af/index.js'),
  'ar-dz': () => import('date-fns/locale/ar-DZ/index.js'),
  'ar-ma': () => import('date-fns/locale/ar-MA/index.js'),
  'ar-sa': () => import('date-fns/locale/ar-SA/index.js'),
  'az': () => import('date-fns/locale/az/index.js'),
  'be': () => import('date-fns/locale/be/index.js'),
  'bg': () => import('date-fns/locale/bg/index.js'),
  'bn': () => import('date-fns/locale/bn/index.js'),
  'ca': () => import('date-fns/locale/ca/index.js'),
  'cs': () => import('date-fns/locale/cs/index.js'),
  'cy': () => import('date-fns/locale/cy/index.js'),
  'da': () => import('date-fns/locale/da/index.js'),
  'de': () => import('date-fns/locale/de/index.js'),
  'el': () => import('date-fns/locale/el/index.js'),
  'en-au': () => import('date-fns/locale/en-AU/index.js'),
  'en-ca': () => import('date-fns/locale/en-CA/index.js'),
  'en-gb': () => import('date-fns/locale/en-GB/index.js'),
  'en-in': () => import('date-fns/locale/en-IN/index.js'),
  'en-nz': () => import('date-fns/locale/en-NZ/index.js'),
  'eo': () => import('date-fns/locale/eo/index.js'),
  'es': () => import('date-fns/locale/es/index.js'),
  'et': () => import('date-fns/locale/et/index.js'),
  'eu': () => import('date-fns/locale/eu/index.js'),
  'fi': () => import('date-fns/locale/fi/index.js'),
  'fr-ca': () => import('date-fns/locale/fr-CA/index.js'),
  'fr-ch': () => import('date-fns/locale/fr-CH/index.js'),
  'fr': () => import('date-fns/locale/fr/index.js'),
  'gd': () => import('date-fns/locale/gd/index.js'),
  'gl': () => import('date-fns/locale/gl/index.js'),
  'gu': () => import('date-fns/locale/gu/index.js'),
  'he': () => import('date-fns/locale/he/index.js'),
  'hi': () => import('date-fns/locale/hi/index.js'),
  'hr': () => import('date-fns/locale/hr/index.js'),
  'hu': () => import('date-fns/locale/hu/index.js'),
  'id': () => import('date-fns/locale/id/index.js'),
  'is': () => import('date-fns/locale/is/index.js'),
  'it': () => import('date-fns/locale/it/index.js'),
  'ja': () => import('date-fns/locale/ja/index.js'),
  'ka': () => import('date-fns/locale/ka/index.js'),
  'kk': () => import('date-fns/locale/kk/index.js'),
  'kn': () => import('date-fns/locale/kn/index.js'),
  'ko': () => import('date-fns/locale/ko/index.js'),
  'lt': () => import('date-fns/locale/lt/index.js'),
  'lv': () => import('date-fns/locale/lv/index.js'),
  'mk': () => import('date-fns/locale/mk/index.js'),
  'ms': () => import('date-fns/locale/ms/index.js'),
  'mt': () => import('date-fns/locale/mt/index.js'),
  'nb': () => import('date-fns/locale/nb/index.js'),
  'nl-be': () => import('date-fns/locale/nl-BE/index.js'),
  'nl': () => import('date-fns/locale/nl/index.js'),
  'nn': () => import('date-fns/locale/nn/index.js'),
  'pl': () => import('date-fns/locale/pl/index.js'),
  'pt-br': () => import('date-fns/locale/pt-BR/index.js'),
  'pt': () => import('date-fns/locale/pt/index.js'),
  'ro': () => import('date-fns/locale/ro/index.js'),
  'ru': () => import('date-fns/locale/ru/index.js'),
  'sk': () => import('date-fns/locale/sk/index.js'),
  'sl': () => import('date-fns/locale/sl/index.js'),
  'sr': () => import('date-fns/locale/sr/index.js'),
  'sv': () => import('date-fns/locale/sv/index.js'),
  'ta': () => import('date-fns/locale/ta/index.js'),
  'te': () => import('date-fns/locale/te/index.js'),
  'th': () => import('date-fns/locale/th/index.js'),
  'tr': () => import('date-fns/locale/tr/index.js'),
  'uk': () => import('date-fns/locale/uk/index.js'),
  'uz': () => import('date-fns/locale/uz/index.js'),
  'vi': () => import('date-fns/locale/vi/index.js'),
  'zh-cn': () => import('date-fns/locale/zh-CN/index.js'),
  'zh-tw': () => import('date-fns/locale/zh-TW/index.js'),
};

/**
 * Used for setting  Moment's locale to match the user's browser settings so that dates
 * appear formatted in an expected way.
 *
 * Example: users with their browser set to UK English are in the en-gb locale
 * and expect to see "May 22nd, 2024" as "22/5/2024".
 **/

type MomentLocaleData = { [key in string]: any };
export const MOMENT_LOCALE_DATA: MomentLocaleData = {
  'af': () => import('moment/dist/locale/af'),
  'ar-dz': () => import('moment/dist/locale/ar-dz'),
  'ar-ma': () => import('moment/dist/locale/ar-ma'),
  'ar-sa': () => import('moment/dist/locale/ar-sa'),
  'az': () => import('moment/dist/locale/az'),
  'be': () => import('moment/dist/locale/be'),
  'bg': () => import('moment/dist/locale/bg'),
  'bn': () => import('moment/dist/locale/bn'),
  'ca': () => import('moment/dist/locale/ca'),
  'cs': () => import('moment/dist/locale/cs'),
  'cy': () => import('moment/dist/locale/cy'),
  'da': () => import('moment/dist/locale/da'),
  'de': () => import('moment/dist/locale/de'),
  'el': () => import('moment/dist/locale/el'),
  'en-au': () => import('moment/dist/locale/en-au'),
  'en-ca': () => import('moment/dist/locale/en-ca'),
  'en-gb': () => import('moment/dist/locale/en-gb'),
  'en-in': () => import('moment/dist/locale/en-in'),
  'en-nz': () => import('moment/dist/locale/en-nz'),
  'eo': () => import('moment/dist/locale/eo'),
  'es': () => import('moment/dist/locale/es'),
  'et': () => import('moment/dist/locale/et'),
  'eu': () => import('moment/dist/locale/eu'),
  'fi': () => import('moment/dist/locale/fi'),
  'fr-ca': () => import('moment/dist/locale/fr-ca'),
  'fr-ch': () => import('moment/dist/locale/fr-ch'),
  'fr': () => import('moment/dist/locale/fr'),
  'gd': () => import('moment/dist/locale/gd'),
  'gl': () => import('moment/dist/locale/gl'),
  'gu': () => import('moment/dist/locale/gu'),
  'he': () => import('moment/dist/locale/he'),
  'hi': () => import('moment/dist/locale/hi'),
  'hr': () => import('moment/dist/locale/hr'),
  'hu': () => import('moment/dist/locale/hu'),
  'id': () => import('moment/dist/locale/id'),
  'is': () => import('moment/dist/locale/is'),
  'it': () => import('moment/dist/locale/it'),
  'ja': () => import('moment/dist/locale/ja'),
  'ka': () => import('moment/dist/locale/ka'),
  'kk': () => import('moment/dist/locale/kk'),
  'kn': () => import('moment/dist/locale/kn'),
  'ko': () => import('moment/dist/locale/ko'),
  'lt': () => import('moment/dist/locale/lt'),
  'lv': () => import('moment/dist/locale/lv'),
  'mk': () => import('moment/dist/locale/mk'),
  'ms': () => import('moment/dist/locale/ms'),
  'mt': () => import('moment/dist/locale/mt'),
  'nb': () => import('moment/dist/locale/nb'),
  'nl-be': () => import('moment/dist/locale/nl-be'),
  'nl': () => import('moment/dist/locale/nl'),
  'nn': () => import('moment/dist/locale/nn'),
  'pl': () => import('moment/dist/locale/pl'),
  'pt-br': () => import('moment/dist/locale/pt-br'),
  'pt': () => import('moment/dist/locale/pt'),
  'ro': () => import('moment/dist/locale/ro'),
  'ru': () => import('moment/dist/locale/ru'),
  'sk': () => import('moment/dist/locale/sk'),
  'sl': () => import('moment/dist/locale/sl'),
  'sr': () => import('moment/dist/locale/sr'),
  'sv': () => import('moment/dist/locale/sv'),
  'ta': () => import('moment/dist/locale/ta'),
  'te': () => import('moment/dist/locale/te'),
  'th': () => import('moment/dist/locale/th'),
  'tr': () => import('moment/dist/locale/tr'),
  'uk': () => import('moment/dist/locale/uk'),
  'uz': () => import('moment/dist/locale/uz'),
  'vi': () => import('moment/dist/locale/vi'),
  'zh-cn': () => import('moment/dist/locale/zh-cn'),
  'zh-tw': () => import('moment/dist/locale/zh-tw'),
} as const;

export const getAgGridKey = (key: string) => key.toUpperCase().replace(/\s+/g, '_');
