import _ from 'lodash';
import moment from 'moment-timezone';
import { sqItemsApi } from '@/sdk/api/ItemsApi';
import { JOURNAL_PREFIX_PATH } from '@/main/app.constants';
import { logError } from '@/utilities/logger';
import { ITEM_TYPES } from '@/trendData/trendData.constants';
import { errorToast, warnToast } from '@/utilities/toast.utilities';
import { isForbidden } from '@/utilities/http.utilities';
import { formatTime } from '@/datetime/dateTime.utilities';
import HttpCodes from 'http-status-codes';

import {
  sqStateSynchronizer,
  sqTrendConditionStore,
  sqWorkbenchStore,
  sqWorkbookStore,
  sqWorksheetStore,
  sqWorkstepsStore,
} from '@/core/core.stores';
import { LINK_TYPE } from '@/annotation/annotation.constants';
import { getWorkstepAction } from '@/worksteps/worksteps.actions';
import { addTrendItem } from '@/trendData/trend.actions';
import { displayRange } from '@/trendData/duration.actions';
import { Item } from '@/utilities/items.types';
import { setLockedChangeMade } from '@/workbook/workbook.actions';

/**
 * Creates and executes Seeq journal links.
 */

/**
 * Executes a link action
 *
 * @param urlParams - an object container
 * @param [urlParams.item] - an item ID
 * @param [urlParams.start] - a start time in UTC milliseconds
 * @param [urlParams.end] - an end time in UTC milliseconds
 * @param [urlParams.workbook] - a workbook ID
 * @param [urlParams.worksheet] - a worksheet ID
 */
export async function executeLinkAction(urlParams: URLSearchParams) {
  const params: {
    item?: string;
    start?: string;
    end?: string;
    workbook?: string;
    worksheet?: string;
    workstep?: string;
    type?: string;
  } = Object.fromEntries(urlParams as any);
  if (params.item) {
    sqItemsApi
      .getItemAndAllProperties({ id: params.item })
      .then(({ data: item }) => addTrendItem(item))
      .catch((response) => {
        if (isForbidden(response)) {
          const currentUser = sqWorkbenchStore.currentUser;
          const messageParams = {
            userName: currentUser.name,
            userId: currentUser.id,
            itemType: params.type,
            itemId: params.item,
          };
          // show warning notification so the user knows the action is not allowed because of insufficient permissions
          warnToast({
            messageKey: 'JOURNAL.ACCESS_WARN_MESSAGE',
            messageParams,
          });
        } else {
          errorToast({ httpResponseOrError: response });
        }
      });
  }

  if (params.start && params.end) {
    displayRange.updateTimes(+params.start, +params.end);
  }

  const workbookId = sqWorkbenchStore.stateParams.workbookId;
  const worksheetId = sqWorkbenchStore.stateParams.worksheetId;
  if (workbookId && worksheetId && params.workstep) {
    const safeGetWorkstepAction = () =>
      getWorkstepAction(params.workbook as string, params.worksheet as string, params.workstep, true).catch(
        (response) => {
          if (_.get(response, 'status') === HttpCodes.NOT_FOUND) {
            warnToast({ messageKey: 'JOURNAL.ENTRY.LINKED_WORKSTEP_GONE' });
          }
        },
      );
    await sqStateSynchronizer.getWorkstepAndRehydrate(safeGetWorkstepAction);
  }

  if (sqWorkbookStore.isLocked) {
    setLockedChangeMade();
  }
}

/**
 * Creates an item link
 *
 * @param item - an item object container
 * @param {String} [item.assets[0].name] - an optional asset name
 * @returns an item link
 */
export function createItemLink(item: Item): string {
  const linkTypes: Record<string, string> = {
    [ITEM_TYPES.SERIES]: LINK_TYPE.SIGNAL,
    [ITEM_TYPES.SCALAR]: LINK_TYPE.SIGNAL,
    [ITEM_TYPES.CONDITION]: LINK_TYPE.CONDITION,
    [ITEM_TYPES.TABLE]: LINK_TYPE.TABLE,
    [ITEM_TYPES.METRIC]: LINK_TYPE.METRIC,
  };

  const type = linkTypes[item.itemType];

  if (_.isNil(type)) {
    logError(`itemType: ${item.itemType} has no matching LINK_TYPE`);
  }

  const name = createItemLinkName(item);

  return !type ? '' : `<a href="${JOURNAL_PREFIX_PATH}?type=${type}&item=${item.id}">${name}</a>`;
}

interface CreateCapsuleLinkParams {
  /** an ID */
  conditionId: string;
  /** UTC Unix start time in milliseconds */
  start: number;
  /** UTC Unix end time in milliseconds */
  end: number;
}

/**
 * Creates a condition link
 *
 * @returns - an object containing the link name and the link
 */
export function createCapsuleLink({ conditionId, start, end }: CreateCapsuleLinkParams): {
  name: string;
  link: string;
} {
  const startLabel = formatTime(start, sqWorksheetStore.timezone);
  const conditionName = sqTrendConditionStore.findItem(conditionId).name;
  const name = `${conditionName} (${startLabel})`;

  return {
    name,
    link: `<a href="${JOURNAL_PREFIX_PATH}?type=${LINK_TYPE.CAPSULE}&item=${conditionId}&start=${start}&end=${end}">${name}</a>`,
  };
}

type CreateTimeRangeLinkParams = {
  /** a label for time range display (e.g. "Display Range") */
  label: string;
  /** UTC Unix start time in milliseconds */
  start: number;
  /** UTC Unix end time in milliseconds */
  end: number;
};

export function createTimeRangeLink({ label, start, end }: CreateTimeRangeLinkParams) {
  const timeRangeLabel = `${formatTime(start, sqWorksheetStore.timezone)} - ${formatTime(
    end,
    sqWorksheetStore.timezone,
  )}`;
  const name = `${label} (${timeRangeLabel})`;
  return {
    name,
    link: `<a href="${JOURNAL_PREFIX_PATH}?type=${LINK_TYPE.RANGE}&start=${start}&end=${end}">${timeRangeLabel}</a>`,
  };
}

/**
 * Creates a workstep link based on the current workstep
 *
 * @returns an object containing the link name and the link
 */
export function createWorkstepLink(stateParams: any) {
  const name = `${sqWorkbookStore.name} - ${sqWorkbookStore.getWorksheetName(stateParams.worksheetId)} (${formatTime(
    moment.utc().valueOf(),
    sqWorksheetStore.timezone,
  )})`;
  return {
    name,
    link: `<a href="${JOURNAL_PREFIX_PATH}?type=${LINK_TYPE.WORKSTEP}&workbook=${stateParams.workbookId}&worksheet=${stateParams.worksheetId}&workstep=${sqWorkstepsStore.current.id}">${name}</a>`,
  };
}

/**
 * Creates a name for an item link
 *
 * @param item - an item object container
 * @param item.name - a name
 * @returns an item link name
 */
export function createItemLinkName(item: Item) {
  return item.name + (item.assets && item.assets.length ? ` (${item.assets[0].name})` : '');
}

/**
 * Replaces the current name of Seeq link with another name
 *
 * @param link - a Seeq link
 * @param name - the new name for the Seeq link
 * @returns a Seeq link with the name replaced
 */
export function replaceLinkName(link: string, name: string): string {
  return link.replace(/(^<.*?>)(.*)(<.*?>$)/g, `$1${name}$3`);
}
